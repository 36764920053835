import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { IActivity } from '../../interfaces/activity';
import * as moment from 'moment';
import { GenerateTimeslots, CloneTimeslotsFromRole } from '../../helpers/timeslot-generator';
import { IResource } from '../../interfaces/resource';
import { MatTableModule, MatTable } from '@angular/material/table';
import { addDuration } from '../../helpers/utility';
import { Utility } from '../../services/utility';
import { CurrentUser } from '../../services/user';

@Component({
  selector: 'activity-administration',
  templateUrl: './activity-administration.component.html'
})

export class ActivityAdministrationComponent implements OnInit, OnDestroy {

  activity: IActivity | null = null;
  availableResources: IResource[];
  selectedTimeslotInterval = null;
  timeslotTable: any = null;
  displayedResourceColumns = ['order', 'name', /*'quantity', 'totalTime',*/ 'remove'];
  private paramsSubscription?: Subscription;
  @ViewChild('associatedResources', { static: false }) associatedResourcesTable: MatTable<any>;
  constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currentUser: CurrentUser) {

  }

  async generateTimeslots(role) {
    await GenerateTimeslots(role,
      this.activity.timeslotInterval || this.selectedTimeslotInterval || '',
      this.activity,
      false, null);
  } 

  resetInterval() {
    delete this.activity.timeslotInterval;
    delete this.selectedTimeslotInterval;
    delete this.activity.timeslots;
  }



  async enabledTimeslotSchemeChanged(role) {
    if (this.activity.enabledTimeslotSchemes[role] && !this.activity.timeslots[role]) {
      this.activity.timeslots[role] = await CloneTimeslotsFromRole(this.activity.timeslots, 'public');
      // this.generateTimeslots(role);
    }
  }


  async addResource(resource) {
    this.activity.associatedResources.push({
      resourceId: resource.id,
      name: resource.name,
      quantity: 1
    });

    this.associatedResourcesTable.renderRows();
  }

  getResourceDuration(associatedResource) {
    let interval = this.availableResources.find(r => r.id == associatedResource.resourceId).timeslotInterval;
    var duration = interval;

    for (var i = 1; i < associatedResource.quantity; i++) {
      duration = addDuration(duration, interval);
    }
    return duration;
  }

  async removeResource(resource) {
    this.activity.associatedResources.splice(this.activity.associatedResources.indexOf(resource), 1);
    this.associatedResourcesTable.renderRows();
  }

  moveResourceUp(resource) {
    let index = this.activity.associatedResources.indexOf(resource);
    if (index > 0) {
      this.activity.associatedResources.splice(index - 1, 0, this.activity.associatedResources.splice(index, 1)[0]);
      this.associatedResourcesTable.renderRows();
    }
  }

  moveResourceDown(resource) {
    let index = this.activity.associatedResources.indexOf(resource);
    if (index < this.activity.associatedResources.length - 2) {
      this.activity.associatedResources.splice(index + 1, 0, this.activity.associatedResources.splice(index, 1)[0]);
      this.associatedResourcesTable.renderRows();
    }
  }

  exclusiveBookingsChanged() {
    this.activity.maxParallelBookings = this.activity.exclusiveBookings ? 1 : -1;
  }

  async clone() {
    let result = await this.api.client().post<any>(`/manage/activities/${this.activity.id}/clone`, {});
    window.location.href = `/client/${this.globals.clientId}/activities/${result.newActivity.id}`;
  }

  async save() {
    if(this.activity.price.withVat == null || this.activity.price.withVat == undefined) {
      this.activity.price = {
        withVat: 0,
        withoutVat: 0,
        vat: this.activity.price.vat,
        vatPercentage: this.activity.price.vatPercentage
      }
    }

    // Check if activity should be created or updated
    if (this.activity && this.activity.id) {
      let result = await this.api.client().patch<any>(`/manage/activities/${this.activity.id}`, this.activity);
    }
    else {
      let result = await this.api.client().put<any>(`/manage/activities`, this.activity);
      if (result.succeeded) {
        this.router.navigateByUrl(`/${this.globals.clientId}/activities/${result.activity.id}`);
      }
    }
  }

  async ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(async (params) => {
      if (params['id']) {
        this.activity = await this.api.client().get<IActivity>(`/manage/activities/${params['id']}`);

        if (this.activity.customTimeslotPeriods) {
          for (let timeslotPeriod of this.activity.customTimeslotPeriods) {
            timeslotPeriod.startDate = moment(timeslotPeriod.startDate).toDate();
            timeslotPeriod.endDate = moment(timeslotPeriod.endDate).toDate();
          }
        }

        if (!this.activity.price) {
          this.activity.price = {
            withVat: 0,
            withoutVat: 0,
            vat: 0,
            vatPercentage: 0
          }
        }

        if (!this.activity.enabledTimeslotSchemes) {
          this.activity.enabledTimeslotSchemes = { public: true }
        }
        if (!this.activity.allocationSettings) {
          this.activity.allocationSettings = { single: {}, multiple: {}, all: {}, spillOver: { adjacentOnly: false } };
        }

        if (!this.activity.allocationSettings.spillOver) {
          this.activity.allocationSettings.spillOver = { adjacentOnly: false };
        }

        if (!this.activity.minPeoplePerBooking) {
          this.activity.minPeoplePerBooking = 1;
        }
        if (!this.activity.maxPeoplePerBooking) {
          this.activity.maxPeoplePerBooking = 100;
        }
        if (!this.activity.maxPeoplePerTimeslot) {
          this.activity.maxPeoplePerTimeslot = 100;
        }

        if (!this.activity.priceType) {
          this.activity.priceType = 'PER_PERSON';
        }

        if (!this.activity.color) {
          this.activity.color = this.utility.randomColor();
        }

        if (typeof (this.activity.minExistingBookings) == 'undefined') {
          this.activity.minExistingBookings = 1;
        }

        if (typeof (this.activity.minExistingPersons) == 'undefined') {
          this.activity.minExistingPersons = 1;
        }

        this.activity.exclusiveBookings = this.activity.maxParallelBookings && this.activity.maxParallelBookings > 0;

        this.availableResources = await this.api.client().get<IResource[]>(`/manage/resources`);
      }
      else {
        this.activity = {
          timeslots: {},
          price: {
            withVat: 0,
            withoutVat: 0,
            vat: 0,
            vatPercentage: 0
          },
          enabledTimeslotSchemes: { public: true },
          associatedResources: [],
          resourceAllocationType: 'SINGLE',
          allocationSettings: { single: {}, multiple: {}, all: {}, spillOver: { adjacentOnly: false } },
          maxParallelBookings: -1,
          restrictTimeslots: true,
          minPeoplePerBooking: 1,
          maxPeoplePerBooking: 100,
          restrictMaxPeoplePerTimeslot: false,
          maxPeoplePerTimeslot: 100,
          priceType: 'PER_PERSON',
          lockToCustomerGroup: false,
          color: this.utility.randomColor()
        };
      }
    });

  }

  async ngOnDestroy() {
    if (this.paramsSubscription)
      this.paramsSubscription.unsubscribe();
  }
}
