import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../services/globals';
import { Utility } from '../../services/utility';
import { MatTable } from '@angular/material/table';
import { newPrice } from '../../interfaces/price';
import { environment } from '../../../environments/environment';
import { Currency } from '../../services/currency';

@Component({
    selector: 'gift-card-template-administration',
    templateUrl: './gift-card-template-administration.component.html',
    styleUrls: ['./gift-card-template-administration.component.scss']
})

export class GiftCardTemplateAdministrationComponent implements OnInit, OnDestroy {
    giftCardTemplate: any;
    private paramsSubscription?: Subscription;
    @ViewChild('tabGroup', { static: false }) tabGroup;

    displayedAvailableAmountColumns = ['title', 'amount', 'price', 'default', 'order', 'remove'];
    @ViewChild('availableOptionsTable', { static: false }) availableOptionsTable: MatTable<any>;
    availableFonts = environment.webSafeFonts;
    amountToAdd = 0;
    constructor(private api: Api, private router: Router, private route: ActivatedRoute, public globals: Globals, private utility: Utility, public currency: Currency) {

    }


    async save() {
        // Check if gift card template should be created or updated
        if (this.giftCardTemplate && this.giftCardTemplate.id) {
            let result = await this.api.client().patch<any>(`/manage/gift-card-templates/${this.giftCardTemplate.id}`, this.giftCardTemplate);
        }
        else {
            let result = await this.api.client().put<any>(`/manage/gift-card-templates`, this.giftCardTemplate);
            if (result.succeeded) {
                this.router.navigateByUrl(`/${this.globals.clientId}/gift-card-templates/${result.giftCardTemplate.id}`);
            }
        }
    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.giftCardTemplate = await this.api.client().get<any>(`/manage/gift-card-templates/${params['id']}`);
            }
            else {
                this.giftCardTemplate = {
                    title: '',
                    summary: '',
                    orientation: 'PORTRAIT',
                    backgroundColor: '#ffffff',
                    backgroundOpacity: 1,
                    logoOpacity: 1,
                    fontFamily: 'Arial',
                    textColor: '#000000',
                    availableOptions: [],
                    callToActionTextColor: '#ffffff',
                    callToActionBackgroundColor: '#000000'
                };
            }
            if (!this.giftCardTemplate.expirationPeriod) {
                this.giftCardTemplate.expirationPeriod = {
                    value: 1,
                    unit: 'years'
                }
            }

            if (!this.giftCardTemplate.callToActionTextColor)
                this.giftCardTemplate.callToActionTextColor = '#ffffff';
            if (!this.giftCardTemplate.callToActionBackgroundColor)
                this.giftCardTemplate.callToActionBackgroundColor = '#000000';

            if (!this.giftCardTemplate.snailMailSettings) {
                this.giftCardTemplate.snailMailSettings = {};
            }
        });

    }

    setDefaultOption(availableOption) {
        for (let option of this.giftCardTemplate.availableOptions) {
            if (availableOption.identifier != option.identifier) {
                option.isDefault = false;
            }
        }
    }
    
    addOption() {
        this.giftCardTemplate.availableOptions.push({
            identifier: this.utility.uuid(),
            title: this.currency.format(this.amountToAdd),
            amount: this.amountToAdd,
            price: newPrice(this.amountToAdd),
            isDefault: !this.giftCardTemplate.availableOptions.length
        });
        this.amountToAdd = 0;
        this.availableOptionsTable.renderRows();
    }

    async removeAvailableOption(option) {
        this.giftCardTemplate.availableOptions.splice(this.giftCardTemplate.availableOptions.indexOf(option), 1);
        this.availableOptionsTable.renderRows();
    }

    moveAvailableOptionUp(option) {
        let index = this.giftCardTemplate.availableOptions.indexOf(option);
        if (index > 0) {
            this.giftCardTemplate.availableOptions.splice(index - 1, 0, this.giftCardTemplate.availableOptions.splice(index, 1)[0]);
            this.availableOptionsTable.renderRows();
        }
    }

    moveAvailableOptionDown(option) {
        let index = this.giftCardTemplate.availableOptions.indexOf(option);
        if (index < this.giftCardTemplate.availableOptions.length - 1) {
            this.giftCardTemplate.availableOptions.splice(index + 1, 0, this.giftCardTemplate.availableOptions.splice(index, 1)[0]);
            this.availableOptionsTable.renderRows();
        }
    }

    async ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }
}
