import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Api } from './services/api';
import { CurrentUser } from './services/user';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { Globals } from './services/globals';
import { MainComponent } from './components/main/main.component';
import { UsersComponent } from './components/users/users.component';
import { UserAdministrationComponent } from './components/users/user-administration.component';
import { LogViewerComponent } from './common/log-viewer/log-viewer.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourceAdministrationComponent } from './components/resources/resource-administration.component';
//import { DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { TimeslotTableComponent } from './components/shared/timeslot-table/timeslot-table.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ActivityAdministrationComponent } from './components/activities/activity-administration.component';
import { CustomTimeslotTablesComponent } from './components/shared/custom-timeslot-tables/custom-timeslot-tables.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PackageAdministrationComponent } from './components/packages/package-administration.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageUploadComponent } from './components/shared/image-upload/image-upload.component';
import { BookingFormsComponent } from './components/booking-forms/booking-forms.component';
import { BookingFormAdministrationComponent } from './components/booking-forms/booking-form-administration.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { PriceInputComponent } from './components/shared/price-input/price-input.component';
import { AddOnsComponent } from './components/add-ons/add-ons.component';
import { AddOnAdministrationComponent } from './components/add-ons/add-on-administration.component';
import { ActivitySequenceEditorComponent } from './components/packages/activity-sequence-editor/activity-sequence-editor.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CheckInCalendarComponent } from './components/calendar/check-in-calendar.component';
import { CalendarDateSelectorComponent } from './components/calendar/calendar-date-selector/calendar-date-selector.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { Utility } from './services/utility';
import { CustomerGroupsComponent } from './components/customer-groups/customer-groups.component';
import { CustomerGroupAdministrationComponent } from './components/customer-groups/customer-group-administration.component';
import { ClientSettingsComponent } from './components/settings/client-settings.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { BookingInfoDialogComponent } from './components/calendar/booking-info-dialog/booking-info-dialog.component';
import { Currency } from './services/currency';
import { CustomerGroupPricesComponent } from './components/shared/customer-group-prices/customer-group-prices.component';
import { CustomTextEditorComponent } from './components/shared/custom-text-editor/custom-text-editor.component';
import { CustomTextsSettingsComponent } from './components/settings/custom-texts/custom-texts-settings.component';
import { CustomSmsTextEditorComponent } from './components/shared/custom-sms-text-editor/custom-sms-text-editor.component';
import { CheckInSectionComponent } from './components/calendar/booking-info-dialog/check-in-section/check-in-section.component';
import { FaceDetectorComponent } from './common/face-detection/face-detector.component';
import { BookingSearchDialogComponent } from './components/calendar/booking-search-dialog/booking-search-dialog.component';
import { AddOnGroupsEditorComponent } from './components/booking-forms/add-on-groups-editor/add-on-groups-editor.component';
import { PriceSettingsEditorComponent } from './components/packages/price-settings-editor/price-settings-editor.component';
import { TimeslotWithPriceSettingsComponent } from './components/packages/timeslot-with-price-settings/timeslot-with-price-settings.component';
import { PromoCodesComponent } from './components/promo-codes/promo-codes.component';
import { PromoCodeAdministrationComponent } from './components/promo-codes/promo-code-administration.component';
import { ClosedPeriodsEditorComponent } from './components/settings/closed-periods/closed-periods-editor.component';
import { CustomerFeedbackEmailsComponent } from './components/customer-feedback-emails/customer-feedback-emails.component';
import { CustomerFeedbackReportComponent } from './components/reports/customer-feedback/customer-feedback-report.component';
import { NgChartsModule } from 'ng2-charts';
import { SmileyReportChartComponent } from './components/reports/customer-feedback/smiley-report-chart/smiley-report-chart.component';
import { FeedbackSectionReportComponent } from './components/reports/customer-feedback/feedback-section-report/feedback-section-report.component';
import { TextFeedbackSectionReportComponent } from './components/reports/customer-feedback/feedback-section-report/text-feedback-section-report/text-feedback-section-report.component';
import { SmileyFeedbackSectionReportComponent } from './components/reports/customer-feedback/feedback-section-report/smiley-feedback-section-report/smiley-feedback-section-report.component';
import { CustomTextsComponent } from './components/custom-texts/custom-texts.component';
import { CustomTextChooserComponent } from './components/shared/custom-text-chooser/custom-text-chooser.component';
import { QuickBookingDialogComponent } from './components/calendar/quick-booking-dialog/quick-booking-dialog.component';
import { QuantityChooserComponent } from './common/quantity-chooser/quantity-chooser.component';
import { BookingSearchDialogBookingComponent } from './components/calendar/booking-search-dialog/booking-search-dialog-booking.component';
import { QuickBookingExtraActivitiesComponent } from './components/calendar/quick-booking-dialog/extra-activities/quick-booking-extra-activities.component';
import { ExtraActivityHelper } from '../../../../src/app/services/extra-activity-helper';
import { Utility as PublicUtility } from '../../../../src/app/services/utility';
import { Globals as PublicGlobals } from '../../../../src/app/services/globals';
import { Api as PublicAPI } from '../../../../src/app/services/api';
import { ReportListComponent } from './components/reports/report-list.component';
import { BookingTableComponent } from './components/reports/booking-list/booking-table/booking-table.component';
import { BookingListReportComponent } from './components/reports/booking-list/booking-list-report.component';
import { BookingListComponent } from './components/reports/booking-list/booking-list/booking-list.component';
import { SalesReportComponent } from './components/reports/sales/sales-report.component';
import { SalesReportTableComponent } from './components/reports/sales/sales-report-table/sales-report-table.component';
import { SalesReportChartComponent } from './components/reports/sales/sales-report-graph/sales-report-chart.component';
import { CheckInCalendarViewComponent } from './components/calendar/check-in-calendar-view/check-in-calendar-view.component';
import { CheckInListViewComponent } from './components/calendar/check-in-list-view/check-in-list-view.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { NotificationGroupComponent } from './components/notification-center/notification-group/notification-group.component';
import { NotificationContentDialogComponent } from './components/notification-center/notification-content-dialog/notification-content-dialog.component';
import { HardwareUnitsComponent } from './components/hardware-units/hardware-units.component';
import { HardwareUnitAdministrationComponent } from './components/hardware-units/hardware-unit-administration.component';
import { GiftCardTemplatesComponent } from './components/gift-card-templates/gift-card-templates.component';
import { GiftCardTemplateAdministrationComponent } from './components/gift-card-templates/gift-card-template-administration.component';
import { ColorChooserComponent } from './components/shared/color-chooser/color-chooser.component';
import { CustomerGroupsChooserComponent } from 'src/app/components/booking-form/customer-groups-chooser/customer-groups-chooser.component';
import { ChangePackageSectionComponent } from './components/calendar/booking-info-dialog/change-package-section/change-package-section.component';
import { CheckInPromptDialogComponent } from './components/calendar/booking-info-dialog/check-in-prompt-dialog/check-in-prompt-dialog.component';
import { PersonCounterComponent } from './components/calendar/booking-info-dialog/person-counter/person-counter.component';
import { GiftCardReportComponent } from './components/reports/gift-cards/gift-card-report.component';
import { HardwareUnitBookingListTypeSettingsComponent } from './components/hardware-units/type-settings/booking-list-type-settings.component';
import { VirtualGiftCardDialogComponent } from './components/calendar/virtual-gift-card-dialog/virtual-gift-card-dialog.component';
import { VikingBowlingGatewayTypeSettingsComponent } from './components/hardware-units/type-settings/viking-bowling-gateway-settings.component';
import { HardwareUnitTestTypeSettingsComponent } from './components/hardware-units/type-settings/test-type-settings.component';
import { QuickBookingComponent } from './components/calendar/quick-booking-dialog/quick-booking/quick-booking.component';
import { QuickResourceBookingComponent } from './components/calendar/quick-booking-dialog/quick-resource-booking/quick-resource-booking.component';
import { ResourceBookingInfoDialogComponent } from './components/calendar/resource-booking-info-dialog/resource-booking-info-dialog.component';
import { BookingSearchDialogResourceBookingComponent } from './components/calendar/booking-search-dialog/booking-search-dialog-resource-booking.component';
import { InvoiceComponent } from './components/invoices/invoice.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { StripeInvoicePaymentComponent } from './components/invoices/payment-providers/stripe-invoice-payment/stripe-invoice-payment.component';
import { DayPlannerReportComponent } from './components/reports/day-planner/day-planner-report.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './components/events/events.component';
import { EventAdministrationComponent } from './components/events/event-administration.component';
import { EventDatesSelectorComponent } from './components/events/event-dates-selector/event-dates-selector.component';
import { StaffDevicesSettingsComponent } from './components/settings/staff-devices/staff-devices.component';
import { StaffDeviceQRDialogComponent } from './components/settings/staff-devices/staff-device-qr-dialog/staff-device-qr-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { EventInfoDialogComponent } from './components/calendar/event-info-dialog/event-info-dialog.component';
import { MediaListComponent } from './components/shared/media-list/media-list.component';
import { TextEditorComponent } from './components/shared/text-editor/text-editor.component';
import { DateTimePickerComponent } from './components/shared/date-time-picker/date-time-picker.component';
import { EventParticipantListComponent } from './components/events/event-participant-list/event-participant-list.component';
import { UnknownTextScannedDialogComponent } from './components/calendar/unknown-text-scanned-dialog/unknown-text-scanned-dialog.component';
import { EventBookingDialogComponent } from './components/event-booking-dialog/event-booking-dialog.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BookingSearchDialogEventBookingComponent } from './components/calendar/booking-search-dialog/booking-search-dialog-event-booking.component';
import { BookingFormMapperComponent } from './components/shared/booking-form-mapper/booking-form-mapper.component';
import { RebookResourceBookingInfoDialogComponent } from './components/calendar/resource-booking-info-dialog/rebook-resource-booking-info-dialog/rebook-resource-booking-info-dialog.component';
import { CashRegisterSettingsComponent } from './components/settings/cash-register-settings/cash-register-settings.component';
import { ArticleNumberChooserComponent } from './components/settings/cash-register-settings/article-number-chooser/article-number-chooser.component';
import { BookingCustomerTableComponent } from './components/reports/booking-list/booking-customer-table/booking-customer-table.component';
import { HcertInfoDialogComponent } from './components/calendar/hcert-info-dialog/hcert-info-dialog.component';
import { HcertScanDialogComponent } from './components/calendar/hcert-scan-dialog/hcert-scan-dialog.component';
import { FirstVisitDialogComponent } from './components/calendar/first-visit-dialog/first-visit-dialog.component';
import { ApiKeysComponent } from './components/settings/api-keys/api-keys.component';
import { QRCodePosterConfiguratorComponent } from './components/shared/qr-code-poster-configurator/qr-code-poster-configurator.component';
import { QRCodePosterPrintComponent } from './components/qr-code-poster-print/qr-code-poster-print.component';
import { GoogleFontDirective } from './directives/google-font/google-font.component';
import { FeedbackSmileyComponent } from './components/shared/feedback-smiley/feedback-smiley.component';
import { ZReportComponent } from './components/reports/z-report/z-report.component';
import { EventReportComponent } from './components/reports/event/event-report.component';
import { ResourceDayReportComponent } from './components/reports/resource-day-report/resource-day-report.component';
import { CharitiesSettingsComponent } from './components/settings/charities/charities-settings.component';
import { PrisonIslandRegistrationDialogComponent } from './components/calendar/booking-info-dialog/third-party/prison-island-registration/prison-island-registration-dialog.component';
import { SupportButtonComponent } from './components/shared/support-button/support-button.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DepartmentAdministrationComponent } from './components/departments/department-administration.component';
import { DepartmentChooserComponent } from './components/shared/department-chooser/department-chooser.component';
import { SwitchResourcesSectionComponent } from './components/calendar/booking-info-dialog/switch-resources-section/switch-resources-section.component';
import { PrisonIslandReportComponent } from './components/reports/prison-island/prison-island-report.component';
import { SocialGamingAdministrationDialogComponent } from './components/calendar/booking-info-dialog/third-party/social-gaming-administration/social-gaming-administration-dialog.component';
import { CheckInCalendarViewNewComponent } from './components/calendar/new/check-in-calendar-view-new.component';
import { BookingIconsComponent } from './components/calendar/booking-icons/booking-icons.component';
import { EventDatesSelectorNewComponent } from './components/events/event-dates-selector-new/event-dates-selector-new.component';
import { AdyenInvoicePaymentComponent } from './components/invoices/payment-providers/adyen-invoice-payment/adyen-invoice-payment.component';
import { ExtendaSettingsComponent } from './components/settings/cash-register-settings/extenda-settings/extenda-settings.component';
import { GiftCardAdministrationComponent } from './components/gift-cards/gift-card-administration.component';
import { MonthlySummaryReportComponent } from './components/reports/monthly-summary-report/monthly-summary-report.component';
import { MonthlySummaryReportKeyFigureComponent } from './components/reports/monthly-summary-report/key-figure/monthly-summary-report-key-figure.component';
import { NewQuantityChooserComponent } from '../../../../src/app/components/booking-form/quantity-chooser/new-quantity-chooser.component';

//import { FullCalendar6Module } from './fullcalendar6.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/api/translations/", "/client");
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HomeComponent,
        LoginComponent,
        UsersComponent,
        UserAdministrationComponent,
        LogViewerComponent,
        ResourcesComponent,
        ResourceAdministrationComponent,
        TimeslotTableComponent,
        ActivitiesComponent,
        ActivityAdministrationComponent,
        CustomTimeslotTablesComponent,
        PackagesComponent,
        PackageAdministrationComponent,
        ImageUploadComponent,
        BookingFormsComponent,
        BookingFormAdministrationComponent,
        PriceInputComponent,
        AddOnsComponent,
        AddOnAdministrationComponent,
        ActivitySequenceEditorComponent,
        CheckInCalendarComponent,
        CalendarDateSelectorComponent,
        CustomerGroupsComponent,
        CustomerGroupAdministrationComponent,
        ClientSettingsComponent,
        UserSettingsComponent,
        BookingInfoDialogComponent,
        CustomerGroupPricesComponent,
        CustomTextEditorComponent,
        CustomTextsSettingsComponent,
        CustomSmsTextEditorComponent,
        CheckInSectionComponent,
        FaceDetectorComponent,
        BookingSearchDialogComponent,
        AddOnGroupsEditorComponent,
        PriceSettingsEditorComponent,
        TimeslotWithPriceSettingsComponent,
        PromoCodesComponent,
        PromoCodeAdministrationComponent,
        ClosedPeriodsEditorComponent,
        CustomerFeedbackEmailsComponent,
        CustomerFeedbackReportComponent,
        SmileyReportChartComponent,
        FeedbackSectionReportComponent,
        TextFeedbackSectionReportComponent,
        SmileyFeedbackSectionReportComponent,
        CustomTextsComponent,
        CustomTextChooserComponent,
        QuickBookingDialogComponent,
        QuantityChooserComponent,
        BookingSearchDialogBookingComponent,
        QuickBookingExtraActivitiesComponent,
        ReportListComponent,
        BookingListReportComponent,
        BookingTableComponent,
        BookingListComponent,
        SalesReportComponent,
        SalesReportTableComponent,
        SalesReportChartComponent,
        CheckInCalendarViewComponent,
        CheckInListViewComponent,
        NotificationCenterComponent,
        NotificationGroupComponent,
        NotificationContentDialogComponent,
        HardwareUnitsComponent,
        HardwareUnitAdministrationComponent,
        GiftCardTemplateAdministrationComponent,
        GiftCardTemplatesComponent,
        ColorChooserComponent,
        CustomerGroupsChooserComponent,
        ChangePackageSectionComponent,
        CheckInPromptDialogComponent,
        PersonCounterComponent,
        GiftCardReportComponent,
        HardwareUnitBookingListTypeSettingsComponent,
        VirtualGiftCardDialogComponent,
        VikingBowlingGatewayTypeSettingsComponent,
        HardwareUnitTestTypeSettingsComponent,
        QuickBookingComponent,
        QuickResourceBookingComponent,
        ResourceBookingInfoDialogComponent,
        BookingSearchDialogResourceBookingComponent,
        InvoicesComponent,
        InvoiceComponent,
        StripeInvoicePaymentComponent,
        DayPlannerReportComponent,
        ChangelogComponent,
        EventsComponent,
        EventAdministrationComponent,
        EventDatesSelectorComponent,
        StaffDevicesSettingsComponent,
        StaffDeviceQRDialogComponent,
        EventInfoDialogComponent,
        MediaListComponent,
        TextEditorComponent,
        DateTimePickerComponent,
        EventParticipantListComponent,
        UnknownTextScannedDialogComponent,
        EventBookingDialogComponent,
        ResetPasswordComponent,
        BookingSearchDialogEventBookingComponent,
        BookingFormMapperComponent,
        RebookResourceBookingInfoDialogComponent,
        CashRegisterSettingsComponent,
        ArticleNumberChooserComponent,
        BookingCustomerTableComponent,
        HcertInfoDialogComponent,
        HcertScanDialogComponent,
        FirstVisitDialogComponent,
        ApiKeysComponent,
        QRCodePosterConfiguratorComponent,
        QRCodePosterPrintComponent,
        GoogleFontDirective,
        FeedbackSmileyComponent,
        ZReportComponent,
        EventReportComponent,
        ResourceDayReportComponent,
        CharitiesSettingsComponent,
        PrisonIslandRegistrationDialogComponent,
        SupportButtonComponent,
        DepartmentsComponent,
        DepartmentAdministrationComponent,
        DepartmentChooserComponent,
        SwitchResourcesSectionComponent,
        PrisonIslandReportComponent,
        SocialGamingAdministrationDialogComponent,
        CheckInCalendarViewNewComponent,
        BookingIconsComponent,
        EventDatesSelectorNewComponent,
        AdyenInvoicePaymentComponent,
        ExtendaSettingsComponent,
        GiftCardAdministrationComponent,
        MonthlySummaryReportComponent,
        MonthlySummaryReportKeyFigureComponent,
        NewQuantityChooserComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        NgbModule,
        AppRoutingModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatTableModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatDialogModule,
        MatSliderModule,
        MatRadioModule,
        MatSlideToggleModule,
      //  DlDateTimePickerModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FileUploadModule,
        CKEditorModule,
        FullCalendarModule,
        ColorPickerModule,
        MatSidenavModule,
        NgChartsModule,
        MatSnackBarModule,
        QRCodeModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatDividerModule,
        //   FullCalendar6Module
    ],
    providers: [
        Globals,
        Api,
        CurrentUser,
        Utility,
        Currency,
        ExtraActivityHelper,
        PublicUtility,
        PublicGlobals,
        PublicAPI,
        MatDatepickerModule,
        { provide: MAT_DATE_LOCALE, useValue: navigator.languages[0] || navigator.language }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
