import { Component, OnInit, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Api } from '../../../services/api';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../services/globals';
import { CurrentUser } from '../../../services/user';


@Component({
    selector: 'virtual-gift-card-dialog',
    templateUrl: './virtual-gift-card-dialog.component.html',
    styleUrls: ['./virtual-gift-card-dialog.component.scss']
})

export class VirtualGiftCardDialogComponent implements OnInit {

    window: any = window;

    @ViewChild('codeScanInput', { static: false })
    codeScanInput: ElementRef<HTMLInputElement>;

    newGiftCard: {
        value: number,
        name?: string,
        email?: string,
        sendEmail?: boolean,
        validMonths: number,
        code?: string
    } = {
            value: null,
            validMonths: 12
        }

    scanMode = false;
    codeScanStarted = false;
    codeScanFinished = false;
    codeFailed = false;
    creatingGiftCard = false;
    giftCardCreated = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<VirtualGiftCardDialogComponent>, private api: Api, public translate: TranslateService, public currentUser: CurrentUser, public globals: Globals) {
        if (data && data.code) {
            this.newGiftCard.code = data.code;
        }


        dialogRef.afterClosed().subscribe(() => {
            this.window.textScanDisabled = false;
        })

        this.window.textScanDisabled = true;

    }

    startScanMode() {
        this.scanMode = true;
        this.newGiftCard.code = '';
        setTimeout(() => {
            this.codeScanInput.nativeElement.focus()
        }, 100);
    }

    async createGiftCard() {
        this.creatingGiftCard = true;
        let result = await this.api.client().post<any>(`/virtual-gift-cards/consume`, this.newGiftCard);

        if (result.succeeded) {
            this.giftCardCreated = true;
        }
        else {
            this.codeFailed = true;
        }

        this.creatingGiftCard = false;
    }

    codeScanKeyUp() {
        if (!this.codeScanStarted) {
            this.codeScanStarted = true;
            setTimeout(() => {
                this.scanMode = false;
                this.codeScanFinished = true;
            }, 300);
        }
    }

    close() {
        this.window.textScanDisabled = false;
        this.dialogRef.close();
    }

    async ngAfterViewInit() {

    }



    async ngOnInit() {


    }
}
