import { Component, OnInit, ElementRef, Inject, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Globals } from '../../../../services/globals';
import { Currency } from '../../../../services/currency';
import { Api } from '../../../../services/api';
import { cloneDeep } from 'lodash';
import { IBookingForm } from '../../../../interfaces/booking-form';
import { IBookingInfo, IBookingPackage, IBookingAddOn } from 'src/app/interfaces/booking';
import { IClientSettings } from 'src/app/interfaces/client-settings';
import { ICustomerGroup } from 'src/app/interfaces/customer-group';
import { ISelectedAddOns } from 'src/app/interfaces/booking-form-state';
import { IPackage } from 'projects/client-app/src/app/interfaces/package';

// CHANGES TO THIS FILE MAY NEED TO BE MADE IN FUNBUTLER STAFF COMPANION ALSO

export interface IChangedPackageInfo {
    bookingPackage: IBookingPackage,
    selectedAddOns: ISelectedAddOns
}


@Component({
    selector: 'change-package-section',
    templateUrl: './change-package-section.component.html',
    styleUrls: ['./change-package-section.component.scss']
})
export class ChangePackageSectionComponent implements OnInit {
    @Input()
    booking: IBookingInfo;
    @Input()
    bookingPackage: IBookingPackage;

    @Output()
    packageHasChanged = new EventEmitter()

    clientSettings: IClientSettings;
    loading: boolean;
    availableSlots: number = 0;
    minPersons: number = 0;
    availableCustomerGroups: any[];
    bookingForm: IBookingForm;

    originalPersons: number;
    originalCustomerGroups: ICustomerGroup[];
    originalAddOns: IBookingAddOn[];

    autoUpdateAddons: boolean = false;
    selectedAddOns: ISelectedAddOns = {};

    customerGroups: any = null;

    package: any;

    @Output()
    bookingChanged = new EventEmitter();

    showAddOns: boolean;

    constructor(private api: Api, public globals: Globals, public currency: Currency, private translate: TranslateService, private elementRef: ElementRef, @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    reset() {
        this.bookingPackage.persons = this.originalPersons;
        this.bookingPackage.customerGroups = this.originalCustomerGroups;
        this.bookingPackage.addOns = this.originalAddOns;
    }

    async initBookingPackage() {
        this.loading = true;
        this.originalPersons = this.bookingPackage.persons;
        this.originalCustomerGroups = cloneDeep(this.bookingPackage.customerGroups);
        this.originalAddOns = cloneDeep(this.bookingPackage.addOns);
        this.selectedAddOns = {};
        this.package = await this.api.publicClient().get<any>(`/packages/${this.bookingPackage.packageId}`);

        if (this.bookingPackage.bookingFormId) {
            this.bookingForm = await this.api.publicClient().get<IBookingForm>(`/booking-forms/${this.bookingPackage.bookingFormId}`);
            if (this.bookingForm) {
                this.customerGroups = this.bookingForm.customerGroups;

                // If the package has a selected customer group, only this one should be changeable
                if (this.bookingForm.useExclusiveCustomerGroups && this.bookingPackage.selectedCustomerGroupId)
                    this.customerGroups = this.customerGroups.filter(g => g.customerGroupId == this.bookingPackage.selectedCustomerGroupId)
            }
        }
        else {
            this.customerGroups = await this.api.publicClient().get<any>(`/customer-groups`);
            for (let customerGroup of this.customerGroups) {
                customerGroup.customerGroupId = customerGroup.id;
            }
        }

        console.log(`Customer groups`);
        console.log(this.customerGroups);



        let result = await this.api.publicClient().get<any>(`/bookings/${this.booking.id}/change/persons-and-add-ons/available-slots?uniquePackageId=${encodeURIComponent(this.bookingPackage.uniquePackageId)}`);
        this.availableSlots = result.maxPersons;
        this.minPersons = result.minPersons;



        // Add the booking package addOns to the selected addOns
        this.autoUpdateAddons = true;
        for (let addOn of this.bookingPackage.addOns) {
            this.selectedAddOns[addOn.addOnId] = addOn;
            if (addOn.quantity != this.bookingPackage.persons)
                this.autoUpdateAddons = false;
        }

        for (let addOnId in this.package.enabledAddOnIds) {
            if (this.package.enabledAddOnIds[addOnId] && !this.selectedAddOns[addOnId]) {
                let addOn = await this.api.publicClient().get<any>(`/add-ons/${addOnId}`);
                this.selectedAddOns[addOnId] = {
                    addOnId: addOnId,
                    name: addOn.name,
                    quantity: 0
                }
            }
        }

        console.log(`selectedAddOns:`);
        console.log(this.selectedAddOns);


        this.loading = false;
    }

    async change() {
        this.loading = true;
        // Replace the addOns of the package with the selected addOns
        this.bookingPackage.addOns = [];
        for (let addOnId in this.selectedAddOns) {
            this.bookingPackage.addOns.push(this.selectedAddOns[addOnId] as IBookingAddOn);
        }

        let result = await this.api.publicClient().post<any>(`/bookings/${this.booking.id}/change/persons-and-add-ons/to?uniquePackageId=${encodeURIComponent(this.bookingPackage.uniquePackageId)}`, this.bookingPackage);


        if (result.succeeded) {
            this.bookingChanged.emit({ booking: result.booking, refetch: true });
        }
        this.loading = false;
    }

    hasAddOns() {
        return Object.keys(this.package.enabledAddOnIds).length;
    }

    addOnQuantityChanged(addOn, value) {
        addOn.value.quantity = value;
        this.packageChanged();
    }

    packageChanged() {
        let data: IChangedPackageInfo = {
            bookingPackage: this.bookingPackage,
            selectedAddOns: this.selectedAddOns
        };
        this.packageHasChanged.emit(data);
    }


    async personsChanged(data) {
        let originalPersons = this.bookingPackage.persons;

        this.bookingPackage.persons = data.value;

        if (this.autoUpdateAddons) {
            for (let addOnId in this.selectedAddOns) {
                if (this.selectedAddOns[addOnId].quantity)
                    this.selectedAddOns[addOnId].quantity = data.value;
            }
        }
        if (data.value != originalPersons)
            this.packageChanged();
    }


    async close() {
        this.reset();
    }



    async ngOnInit() {
        this.booking = cloneDeep(this.booking);
        this.bookingPackage = cloneDeep(this.bookingPackage);
        this.initBookingPackage();
    }
}
