// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virtual-gift-card-dialog-content {
  width: 700px;
}

.virtual-gift-card-dialog {
  font-size: 20px;
}
.virtual-gift-card-dialog .scan-icon {
  font-size: 100px;
  width: auto;
  height: auto;
}
.virtual-gift-card-dialog .scan-icon.big {
  font-size: 140px;
}
.virtual-gift-card-dialog .code-scan-input {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/calendar/virtual-gift-card-dialog/virtual-gift-card-dialog.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/calendar/virtual-gift-card-dialog/virtual-gift-card-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;ACCJ;;ADEA;EACI,eAAA;ACCJ;ADCI;EACI,gBAAA;EACA,WAAA;EACA,YAAA;ACCR;ADAQ;EACI,gBAAA;ACEZ;ADEI;EACI,UAAA;ACAR","sourcesContent":[".virtual-gift-card-dialog-content{\r\n    width: 700px;\r\n}\r\n\r\n.virtual-gift-card-dialog {\r\n    font-size: 20px;\r\n\r\n    .scan-icon {\r\n        font-size: 100px;\r\n        width: auto;\r\n        height: auto;\r\n        &.big {\r\n            font-size: 140px;\r\n        }\r\n    }\r\n\r\n    .code-scan-input {\r\n        opacity: 0;\r\n    }\r\n}\r\n",".virtual-gift-card-dialog-content {\n  width: 700px;\n}\n\n.virtual-gift-card-dialog {\n  font-size: 20px;\n}\n.virtual-gift-card-dialog .scan-icon {\n  font-size: 100px;\n  width: auto;\n  height: auto;\n}\n.virtual-gift-card-dialog .scan-icon.big {\n  font-size: 140px;\n}\n.virtual-gift-card-dialog .code-scan-input {\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
