// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internal-description {
  font-style: italic;
  opacity: 0.7;
}

.support-pos {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./projects/client-app/src/app/components/packages/packages.component.scss","webpack://./../../../../../Funbutler%20Booking/src/public/apps/public-app/projects/client-app/src/app/components/packages/packages.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;ACCF;;ADEA;EACE,kBAAA;ACCF","sourcesContent":[".internal-description {\r\n  font-style: italic;\r\n  opacity: 0.7;\r\n}\r\n\r\n.support-pos {\r\n  position: absolute;\r\n}",".internal-description {\n  font-style: italic;\n  opacity: 0.7;\n}\n\n.support-pos {\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
